// This file configures the initialization of Sentry on the client.
// The config added here will be used whenever a users loads a page in their browser.

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"270f5868f3e3a2c6382437b82c4164855c3cebaa"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.75,
    debug: false,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}
